import { Button, Input } from '@chakra-ui/react';
import { type GetServerSideProps } from 'next';
import Head from 'next/head';
import { useId, type FC } from 'react';
import './login.css';
import { auth0 } from '@/util/auth0/server';

type LoginProps = {
  auth0QueryParams: Record<string, string | string[]>;
  orgName?: string;
};

export const getServerSideProps: GetServerSideProps<LoginProps> = async (context) => {
  const session = await auth0.getSession(context.req, context.res);

  if (session?.accessTokenExpiresAt * 1000 > Date.now()) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  // TODO: change the rest of the site to use `return-to` to be consistent with
  // how we handle query-params.
  context.query['return-to'] = context.query['return-to'] ?? context.query.returnTo ?? '/';
  delete context.query.returnTo;

  const orgName = context.query['org-name']?.toString() ?? '';
  delete context.query['org-name'];

  return Promise.resolve({
    props: {
      auth0QueryParams: context.query,
      orgName,
    },
  });
};

/**
 * Builds a login form that redirects to Auth0 for authentication.
 * Accepts the following query-parameters:
 * * `org-name` - the organization code, e.g. pizza_co; optional; prompts the
 *   user if not specified
 * * `return-to` - the URL to redirect to after login; optional; defaults to `/`
 *   if not specified
 * * `returnTo` - an alias for `return-to`
 * * any other query-parameters, e.g. `connection` are passed through to Auth0
 */
const Login: FC<LoginProps> = ({ auth0QueryParams, orgName }) => {
  const orgFieldID = useId();

  return (
    <form className="login-form" action="/api/auth/login" method="get">
      <Head>
        <title>Clarasight | Login</title>
      </Head>

      <legend>Sign in to Clarasight</legend>

      {Object.entries(auth0QueryParams).map(([key, value]) => (
        <input key={key} type="hidden" name={key} value={value} />
      ))}

      <label htmlFor={orgFieldID}>Organization</label>
      <Input
        id={orgFieldID}
        defaultValue={orgName}
        name="org-name"
        type="text"
        onBlur={(e) => {
          e.target.value = e.target.value.toLowerCase();
        }}
        pattern="^[a-z0-9_\-]+$"
        placeholder="example_co"
        required
        autoFocus
      />
      <p className="field__help">
        This is all lowercase, with words separated by underscores or dashes, e.g.{' '}
        <code className="nowrap">example-co</code>.
      </p>

      <footer>
        <Button type="submit">Sign in</Button>
      </footer>
    </form>
  );
};

export default Login;
